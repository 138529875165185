import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js"; // Updated import
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import { useTranslation } from 'react-i18next';

import image9 from "images/9.jpeg";
import image10 from "images/10.jpeg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>{t('aboutUs.subheading')}</Subheading>}
        heading={t('aboutUs.heading')}
        description={t('aboutUs.description')}
        buttonRounded={false}
        primaryButtonText={t('aboutUs.buttonText')}
        primaryButtonUrl="/"
        imageSrc={image9}
      />
      <MainFeature1
        subheading={<Subheading>{t('aboutUs.purposeSubheading')}</Subheading>}
        heading={t('aboutUs.purposeHeading')}
        description={t('aboutUs.purposeDescription')}
        buttonRounded={false}
        primaryButtonText={t('aboutUs.purposeButtonText')}
        imageSrc={image10}
        textOnLeft={false}
        primaryButtonUrl="/contact"
      />
      <Features
        subheading={<Subheading>{t('aboutUs.productsSubheading')}</Subheading>}
        heading={t('aboutUs.productsHeading')}
        description={t('aboutUs.productsDescription')}
        primaryButtonUrl="/"
      />
      <Footer /> {/* Updated Footer */}
    </AnimationRevealPage>
  );
};
