import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { useTranslation } from "react-i18next";

const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: t('contactDetails.bulgaria'),
            description: (
              <>
                <Email>servicevegandelight@gmail.com</Email>
                <Phone> +359 879 267 283</Phone>
              </>
            )
          },
          {
            title: t('contactDetails.mexico'),
            description: (
              <>
                <Email>servicevegandelight@gmail.com</Email>
                <Phone> +52 984 211 3727</Phone>
              </>
            )
          },
         /* {
            title: "California",
            description: (
              <>
                <Address>
                  <AddressLine>96 NE. Delaware Lane</AddressLine>
                  <AddressLine>Sacramento, CA 95820</AddressLine>
                </Address>
                <Email>contact@vegandelight.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          }*/
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
