import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as WhatsappIcon } from "images/whatsapp-icon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Container = tw(ContainerBase)`bg-gray-200 text-gray-700 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const Row = tw.div`flex flex-col lg:flex-row items-center justify-between px-8 space-y-8 lg:space-y-0 lg:space-x-16`;

const LogoContainer = tw.div`flex flex-col items-center justify-center md:justify-start mb-8 lg:mb-0`;
const LogoImg = styled.img`
  ${tw`w-64 h-64 mb-4`}
  @media (max-width: 768px) {
    ${tw`w-32 h-32`}
  }
`;
const LogoText = tw.h5`text-2xl font-black tracking-wider text-black`;

const LinksContainer = tw.div`flex flex-col lg:flex-row justify-center lg:justify-start items-center space-y-4 lg:space-y-0 lg:space-x-8`;
const FooterLink = styled(Link)`
  ${tw`text-lg my-2 lg:my-0 lg:mx-4 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-black hocus:text-black`}
`;

const SocialLinksContainer = tw.div`flex justify-center space-x-4`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-700 hover:text-black transition duration-300`}
  svg {
    ${tw`w-8 h-8 fill-current text-gray-700 hover:text-black transition duration-300`}
  }
`;

const ContactInfo = tw.div`text-center lg:text-left space-y-2`;
const ContactItem = styled.div`
  ${tw`mt-2 text-lg font-semibold tracking-wide`}
  a {
    ${tw`text-lg font-semibold tracking-wide`}
  }
`;

const CopyrightText = tw.p`text-center mt-16 lg:mt-8 font-medium tracking-wide text-sm text-gray-600`;

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Vegan Delight</LogoText>
          </LogoContainer>
          <LinksContainer>
            <FooterLink to="/">{t('footer.home')}</FooterLink>
            <FooterLink to="/about">{t('footer.about')}</FooterLink>
            <FooterLink to="/contact">{t('footer.contact')}</FooterLink>
          </LinksContainer>
          <div className="flex flex-col lg:flex-row items-center lg:space-x-16 space-y-8 lg:space-y-0">
            <SocialLinksContainer>
              <SocialLink href="https://facebook.com">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://wa.me/+529842113727" target="_blank" rel="noopener noreferrer">
                <WhatsappIcon />
              </SocialLink>
            </SocialLinksContainer>
            <ContactInfo>
              <ContactItem>{t('footer.email')}: <a href="mailto:servicevegandelight@gmail.com">servicevegandelight@gmail.com</a></ContactItem>
              <ContactItem>{t('footer.phone')}: <a href="tel:+529842113727">+52 984 211 3727</a></ContactItem>
              <ContactItem>{t('footer.phone')}: <a href="tel:+529842113727">+359 879 267 283</a></ContactItem>
            </ContactInfo>
          </div>
        </Row>
        <CopyrightText>
          &copy; {new Date().getFullYear()} Vegan Delight. {t('footer.allRightsReserved')}
        </CopyrightText>
      </Content>
    </Container>
  );
};
