import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Link } from 'react-router-dom';
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { useTranslation } from 'react-i18next';

import logo from "../../images/logo.svg";
import englishIcon from "../../images/english.png";
import spanishIcon from "../../images/spanish.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
  px-4 lg:px-8 py-2
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw(Link)`
  text-xl my-2 lg:my-0 lg:mx-4
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-black hocus:text-black
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-black text-gray-100
  hocus:bg-gray-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-48 h-32 lg:w-128 lg:h-72 mr-4`} // Adjust the size of the logo
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-black transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const LanguageIconContainer = styled.div`
  ${tw`flex items-center justify-center mt-4`}
`;

const LanguageIcon = styled.img`
  ${tw`w-10 h-10 rounded-full cursor-pointer mx-1 transition duration-300`}
  ${({ selected }) => selected && css`
    border: 2px solid black;
    padding: 2px;
  `}
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  const { t, i18n } = useTranslation();

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink to="/">{t('header.home')}</NavLink>
      <NavLink to="/about">{t('header.about')}</NavLink>
      <NavLink to="/contact">{t('header.contact')}</NavLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink to="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (showNavLinks) toggleNavbar();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showNavLinks, toggleNavbar]);

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
        <LanguageIconContainer>
          <LanguageIcon src={englishIcon} onClick={() => changeLanguage('en')} selected={i18n.language === 'en'} />
          <LanguageIcon src={spanishIcon} onClick={() => changeLanguage('es')} selected={i18n.language === 'es'} />
        </LanguageIconContainer>
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
          <LanguageIconContainer>
            <LanguageIcon src={englishIcon} onClick={() => changeLanguage('en')} selected={i18n.language === 'en'} />
            <LanguageIcon src={spanishIcon} onClick={() => changeLanguage('es')} selected={i18n.language === 'es'} />
          </LanguageIconContainer>
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
