import React from "react";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js"; // Updated import

// Import renamed images
import image1 from "images/1.jpeg";
import image2 from "images/2.jpeg";
import image9 from "images/9.jpeg";
import image10 from "images/10.jpeg";
import image11 from "images/11.jpeg";
import image6 from "images/6.jpeg";
import image7 from "images/7.jpeg";
import image8 from "images/8.jpeg";

export default () => {
  const { t } = useTranslation();
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-black text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>{t('restaurantLandingPage.heroHeadingPart1')} <HighlightedText>{t('restaurantLandingPage.heroHeadingPart2')}</HighlightedText></>}
        description={t('restaurantLandingPage.heroDescription')}
        imageSrc={image1}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />
      <MainFeature
        subheading={<Subheading>{t('restaurantLandingPage.aboutUsSubheading')}</Subheading>}
        heading={
          <>
            <HighlightedText>{t('restaurantLandingPage.aboutUsHeading')}</HighlightedText>
          </>
        }
        description={
          <Description>
            {t('restaurantLandingPage.aboutUsDescription')}
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={image2}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText = {t('restaurantLandingPage.learnMore')}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <TabGrid heading={<>{t('restaurantLandingPage.checkoutProductsHeadingPart1')} <HighlightedText>{t('restaurantLandingPage.checkoutProductsHeadingPart2')}</HighlightedText></>} />
      <Features
        heading={
          <>
            {t('restaurantLandingPage.amazingServicesHeadingPart1')} <HighlightedText>{t('restaurantLandingPage.amazingServicesHeadingPart2')}</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: image6,
            title: t('restaurantLandingPage.organicProductsTitle'),
            description: t('restaurantLandingPage.organicProductsDescription'),
            url: "/about"
          },
          {
            imageSrc: image7,
            title: t('restaurantLandingPage.healthyIngredientsTitle'),
            description: t('restaurantLandingPage.healthyIngredientsDescription'),
            url: "/about"
          },
          {
            imageSrc: image8,
            title: t('restaurantLandingPage.sustainableSourcingTitle'),
            description: t('restaurantLandingPage.sustainableSourcingDescription'),
            url: "/about"
          }
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>{t('restaurantLandingPage.ourValuesSubheading')}</Subheading>}
        heading={<>{t('restaurantLandingPage.whyChooseUsHeadingPart1')} <HighlightedText>{t('restaurantLandingPage.whyChooseUsHeadingPart2')}</HighlightedText></>}
        statistics={[
          {
            key: t('restaurantLandingPage.statisticsProducts'),
            value: "50+",
          },
          {
            key: t('restaurantLandingPage.statisticsHappyCustomers'),
            value: "100+"
          },
          {
            key: t('restaurantLandingPage.statisticsYears'),
            value: "1+"
          }
        ]}
        description={t('restaurantLandingPage.ourValuesDescription')}
        imageInsideDiv={false}
        imageSrc={image9}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        primaryButtonText = {t('restaurantLandingPage.learnMore')}
        textOnLeft={true}
      />
      <Testimonial
        subheading={<Subheading>{t('restaurantLandingPage.testimonialsSubheading')}</Subheading>}
        heading={<>{t('restaurantLandingPage.testimonialsHeadingPart1')} <HighlightedText>{t('restaurantLandingPage.testimonialsHeadingPart2')}</HighlightedText></>}
        testimonials={[
          {
            imageSrc: image10,
            quote: t('restaurantLandingPage.review1.content'),
            customerName: t('restaurantLandingPage.review1.name')
          },
          {
            imageSrc: image11,
            quote: t('restaurantLandingPage.review2.content'),
            customerName: t('restaurantLandingPage.review2.name')
          },
          {
            imageSrc: image8,
            quote: t('restaurantLandingPage.review3.content'),
            customerName: t('restaurantLandingPage.review3.name')
          },
          {
            imageSrc: image6,
            quote: t('restaurantLandingPage.review4.content'),
            customerName: t('restaurantLandingPage.review4.name')
          },
          {
            imageSrc: image7,
            quote: t('restaurantLandingPage.review5.content'),
            customerName: t('restaurantLandingPage.review5.name')
          },
          {
            imageSrc: image9,
            quote: t('restaurantLandingPage.review6.content'),
            customerName: t('restaurantLandingPage.review6.name')
          },
        ]}
      />
      <Footer /> {/* Updated Footer */}
    </AnimationRevealPage>
  );
};
