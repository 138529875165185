import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import image1 from "images/1.jpeg";
import image3 from "images/3.jpeg";
import image4 from "images/4.jpeg";
import image5 from "images/5.jpeg";
import image6 from "images/6.jpeg";
import image7 from "images/7.jpeg";
import image8 from "images/8.jpeg";
import image9 from "images/9.jpeg";
import image10 from "images/10.jpeg";
import image11 from "images/11.jpeg";
import image12 from "images/12.jpeg";
import image13 from "images/13.jpeg";
import image14 from "images/14.jpeg";
import image15 from "images/15.jpeg";
import image16 from "images/16.jpeg";
import image17 from "images/17.jpeg";
import image18 from "images/18.jpeg";
import image19 from "images/19.jpeg";
import image20 from "images/20.jpeg";
import image21 from "images/21.jpeg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)`text-black`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = styled(motion.div)`
  ${tw`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}
  &:hover {
    ${tw`shadow-xl`}
  }
`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-black`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-black`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({ heading = "Checkout our Products" }) => {
  const { t } = useTranslation();
  const products = [
    {
      imageSrc: image18,
      title: t('products.lavenderTea.title'),
      content: t('products.lavenderTea.content'),
      rating: "4.9",
      reviews: "102",
      url: ""
    },
    {
      imageSrc: image19,
      title: t('products.lindenTea.title'),
      content: t('products.lindenTea.content'),
      rating: "4.8",
      reviews: "95",
      url: ""
    },
    {
      imageSrc: image5,
      title: t('products.nettleTea.title'),
      content: t('products.nettleTea.content'),
      rating: "4.9",
      reviews: "89",
      url: ""
    },
    {
      imageSrc: image20,
      title: t('products.matchaTea.title'),
      content: t('products.matchaTea.content'),
      rating: "4.9",
      reviews: "94",
      url: ""
    },
    {
      imageSrc: image21,
      title: t('products.camoMile.title'),
      content: t('products.camoMile.content'),
      rating: "4.9",
      reviews: "94",
      url: ""
    },
    {
      imageSrc: image7,
      title: t('products.speltFlour.title'),
      content: t('products.speltFlour.content'),
      rating: "4.7",
      reviews: "110",
      url: ""
    },
    {
      imageSrc: image6,
      title: t('products.ryeFlour.title'),
      content: t('products.ryeFlour.content'),
      rating: "4.8",
      reviews: "98",
      url: ""
    },
    {
      imageSrc: image8,
      title: t('products.speltPasta.title'),
      content: t('products.speltPasta.content'),
      rating: "4.7",
      reviews: "76",
      url: ""
    },
    {
      imageSrc: image3,
      title: t('products.lavenderHoney.title'),
      content: t('products.lavenderHoney.content'),
      rating: "4.9",
      reviews: "87",
      url: ""
    },
    {
      imageSrc: image1,
      title: t('products.chestnutHoney.title'),
      content: t('products.chestnutHoney.content'),
      rating: "5",
      reviews: "98",
      url: ""
    },
    {
      imageSrc: image4,
      title: t('products.lindenHoney.title'),
      content: t('products.lindenHoney.content'),
      rating: "4.8",
      reviews: "92",
      url: ""
    },
    {
      imageSrc: image9,
      title: t('products.tribulusTea.title'),
      content: t('products.tribulusTea.content'),
      rating: "4.7",
      reviews: "85",
      url: ""
    },
    {
      imageSrc: image10,
      title: t('products.culturedPorcini.title'),
      content: t('products.culturedPorcini.content'),
      rating: "4.9",
      reviews: "102",
      url: ""
    },
    {
      imageSrc: image12,
      title: t('products.naturalCashew.title'),
      content: t('products.naturalCashew.content'),
      rating: "4.7",
      reviews: "95",
      url: ""
    },
    {
      imageSrc: image13,
      title: t('products.culturedCashew.title'),
      content: t('products.culturedCashew.content'),
      rating: "4.8",
      reviews: "98",
      url: ""
    },
    {
      imageSrc: image17,
      title: t('products.sundriedTomato.title'),
      content: t('products.sundriedTomato.content'),
      rating: "4.7",
      reviews: "85",
      url: ""
    },
    {
      imageSrc: image14,
      title: t('products.smokedPepperCheese.title'),
      content: t('products.smokedPepperCheese.content'),
      rating: "4.9",
      reviews: "120",
      url: "" // leave blank for now
    },
    {
      imageSrc: image15,
      title: t('products.blackGarlicSpread.title'),
      content: t('products.blackGarlicSpread.content'),
      rating: "4.8",
      reviews: "85",
      url: "" // leave blank for now
    },
    {
      imageSrc: image11,
      title: t('products.cashewYogurt.title'),
      content: t('products.cashewYogurt.content'),
      rating: "4.7",
      reviews: "98",
      url: "" // leave blank for now
    },
    {
      imageSrc: image16,
      title: t('products.truffleSpread.title'),
      content: t('products.truffleSpread.content'),
      rating: "4.9",
      reviews: "110",
      url: "" // leave blank for now
    }
  ];

  const productsWithoutDelishu = products.filter(product => !product.content.startsWith("Delishu"));
  const delishuProduct = products.find(product => product.content.startsWith("Delishu"));

  const tabs = { Products: productsWithoutDelishu };

  const tabsKeys = Object.keys(tabs);
  const [activeTab] = useState(tabsKeys[0]);
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className="group"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
        {delishuProduct && (
          <div tw="w-full mt-8 text-center">
            <p tw="text-xl font-semibold text-red-800">{delishuProduct.content}</p>
          </div>
        )}
        <TabContent
          initial={{ opacity: 0, scale: 0.8, display: "none" }}
          animate={{ opacity: 1, scale: 1, display: "flex" }}
          transition={{ duration: 0.4 }}
        >
          {products
            .filter(product => product.content.startsWith("Delishu"))
            .map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className="group"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
        </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
